@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'animation.css';
@import 'gridWidths.css';
@import 'main-navigation.css';
@import 'border-radii.css';
@import 'spacing.css';
@import 'typography.css';
@import 'form-select.css';

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  @apply bg-white text-abyss;
  font-size: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  @apply no-underline;
}

p {
  margin: 0 0 1em 0;
}

ul {
  list-style: none;
  margin: 0 0 2em 0;
  padding: 0;
}

li {
  font-size: 0.8em;
  margin-bottom: 1em;
}

b,
strong {
  @apply font-semibold;
}

/* This is needed to reverse the CSS normalisation Tailwind applies. Ideally we can remove this
    pretty soon though.
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
}

@layer components {
  .tooltip-text {
    @apply absolute left-1/2 mt-3 w-max -translate-x-1/2 rounded-md bg-black py-2 px-2.5 text-center font-semibold text-white invisible after:absolute
    after:bottom-full after:left-1/2 after:-ml-[5px] after:border-[5px]
    after:border-solid after:border-x-transparent after:border-t-transparent after:border-b-black after:content-[''] md:group-hover:visible;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
